import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { routes } from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base:
    process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PROD : process.env.VUE_APP_LOCAL,
  routes,
  linkActiveClass: 'active'
})

/**
 * checkAuthorizationPermissions
 *
 * Verifica si el usuario tiene autorización basado en sus permisos.
 */
const checkAuthorizationPermissions = async (to, next) => {
  //
  // Obtiene todos los permisos del sistema
  // si es que no están cargados en vuex.
  if (!store.getters['permissionModule/isSetPermissions']) {
    store.dispatch('permissionModule/getPermissions')
  }

  const authPermissions = store.getters['userModule/getAuthPermissions']

  const permissions = to.meta.requiresPermissions

  const requiresPermissions = permissions !== undefined ? permissions.length > 0 : false

  const authorizated = requiresPermissions
    ? authPermissions.some(permission => permissions.includes(permission))
    : true

  if (!authorizated) return next({ name: 'Dashboard' })

  next()
}

/**
 * checkAuthentication
 *
 * Hace una consulta al módulo de usuarios para saber si el usuario actual
 * está autenticado, si es así lo deja continuar, si no, lo redirecciona al login.
 *
 * @param {object} to    Es el objeto que representa la dirección a donde se desea llegar.
 * @param {object} next  Es la función que da pie a que el programa pueda
 *                       continuar o no, o se redireccione a otra ruta.
 */
const checkAuthentication = async (to, next, from) => {
  const isAuthenticated = store.getters['authModule/isAuthenticated']

  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)

  if (requiresAuth && !isAuthenticated) return next('/login')

  if (!requiresAuth && isAuthenticated) return next({ name: 'Dashboard' })

  if (isAuthenticated) return checkAuthorizationPermissions(to, next)

  next()
}

router.beforeEach((to, from, next) => {
  checkAuthentication(to, next, from)
})

export default router
