<template>
  <div v-bind="$attrs">
    <check-authorization :requiresAuthorizations="['listar partidas']">
      <x-select
        v-model="partida"
        getterListPath="partidaModule/getPartidas"
        :formatListWith="['id', 'nombre']"
        no-validate
        default-text="Seleccionar partida presupuestal"
      />
    </check-authorization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'XSelectPartidaPresupuestal',

  data () {
    return {
      partida: null
    }
  },

  props: {
    periodo: null
  },

  computed: {
    ...mapGetters('fiscalPeriodsModule', [
      'getAllFiscalPeriods'
    ])
  },

  watch: {
    partida: {
      immediate: true,
      handler (value) {
        this.onInput(value)
      }
    },

    periodo: {
      immediate: true,
      async handler (value) {
        await this.$store.dispatch('partidaModule/getAll', value)
      }
    }
  },

  methods: {
    onInput (value) {
      this.$emit('input', value)
    }
  }
}
</script>
