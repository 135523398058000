<template>
  <div :class="classBody">
    <b-badge v-if="loading" variant="light">
      Consultado periodo fiscal activo...
    </b-badge>

    <b-badge v-if="!loading && period" :variant="variant">
      Periodo fiscal {{ period.nombre }}
    </b-badge>

    <b-badge
      v-else-if="response.error"
      variant="danger"
      v-b-tooltip.hover.bottom
      title="Error al obtener el periodo fiscal activo"
    >
      Error periodo fiscal
    </b-badge>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'XPeriodoFiscalTag',

  props: {
    classBody: {
      type: [String, Array],
      required: false,
      default: ''
    },

    variant: {
      type: String,
      required: false,
      default: ''
    }
  },

  async mounted () {
    this.loading = true
    const getval = await this.$store.dispatch('fiscalPeriodsModule/getActiveFiscalPeriod')
    this.loading = false

    this.response = getval
  },

  data () {
    return {
      loading: false,

      response: {}
    }
  },

  computed: mapGetters('fiscalPeriodsModule', { period: 'getActiveFiscalPeriod' })
}
</script>
