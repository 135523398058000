import Resource from '@/api/Resource'

const activityLogs = new Resource('/api/activity-logs', 'historyModule')

export default {
  namespaced: true,

  state: {
    activityLogs: [],

    isGettingResource: false
  },

  getters: {
    hasActivityLogs: state => {
      if (state.activityLogs) {
        return state.activityLogs.data && state.activityLogs.data.data && state.activityLogs.data.data.length > 0
      }
      return false
    },

    getActivityLogs: ({ activityLogs }, getters) => {
      return (getters.hasActivityLogs ? activityLogs.data.data : [])
    },

    getAvailableLogs: state => {
      return !state.isGettingResource
    }
  },

  mutations: {
    resetState (state) {
      for (const key in state) {
        state[key] = null
      }
    },

    setActivityLogs (state, activityLogs) {
      state.activityLogs = activityLogs
    },

    setIsGettingResource (state, payload) {
      state.isGettingResource = payload
    }
  },

  actions: {
    async getActivityLogs ({ commit }, page) {
      let retval = {}

      try {
        const { data } = await activityLogs.get('api/activity-logs', {
          page: page
        }, 'setIsGettingResource')

        retval = data

        commit('setActivityLogs', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    },

    async getActivityLogsFromUser ({ commit, rootState }, page = 1) {
      let retval = {}

      try {
        const authUserId = rootState.userModule.authUser.id
        const { data } = await activityLogs.get(`api/activity-logs/${authUserId}?page=${page}`, null, 'setIsGettingResource')

        retval = data

        commit('setActivityLogs', data)
      } catch (error) {
        retval = { error: true, ...error.response.data }
      }

      return retval
    }
  }
}
