<template>
  <div>
    <!--
      @slot Contenido principal el cual, es renderizado si
            alguno de roles del usuario hacen match con
            los establecidos en los props. -->
    <slot v-if="authorized" v-bind:message="getErrorMessage" v-bind:authorized="authorized"></slot>

    <!--
      Dada la prop como 'hide', simplemente no muestra el contenido.
    -->
    <div v-else-if="hide"></div>

    <!--
      @slot 'help'. Slot para mostrar un mensaje de ayuda para
                    el usuario, acepta un slot el cual se puede
                    pasar un formato personalizado, utilizando
                    el prop de nombre 'showElse'.
     -->
    <slot
      v-else-if="showElse && !hide"
      name="help"
      v-bind:message="getErrorMessage"
      v-bind:authorized="authorized"
    ></slot>

    <!--
      Alerta que se muestra por defecto si no se establece alguna opción.
    -->
    <b-alert
      v-else-if="showAlert"
      show
      variant="danger"
      class="text-sm text-danger font-weight-bold"
    >
      {{ getErrorMessage }}
    </b-alert>

    <!-- @IMPORTANTE: Es utilizado únicamente para mandar los datos de validación
                      que realiza el componente, y se debe de manejar su comportamiento
                      fuera del componente, ya no se hace responsable de ocultar o no
                      su contenido.
    -->
    <slot
      v-else-if="noSlots"
      v-bind:message="getErrorMessage"
      v-bind:authorized="authorized"
    ></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckAuthorization',

  props: {
    /**
     * Roles que tienen authorización a ver el
     * contenido del componente.
     */
    requiresAuthorizations: {
      type: Array,
      default: () => []
    },

    requiresRoles: {
      type: Array,
      default: () => []
    },

    overrideIf: {
      type: Boolean,
      default: false
    },

    showElse: {
      type: Boolean,
      default: false
    },

    hide: {
      type: Boolean,
      required: false,
      default: false
    },

    noSlots: {
      type: Boolean,
      required: false,
      default: false
    },

    showAlert: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  created () {
    this.checkPermissions()
  },

  computed: {
    ...mapGetters('userModule', ['getAuthPermissions', 'getAuthRoles']),

    getErrorMessage () {
      if (this.requiresRoles.length > 0) {
        return `Roles autorizados: ${this.requiresRoles.map(e => "'" + e + "' ")}`
      }

      return `Permiso requerido: ${this.requiresAuthorizations.map(e => "'" + e + "' ")}`
    }
  },

  data () {
    return {
      authorized: false
    }
  },

  watch: {
    /**
     * 'getAuthPermissions'. Del módulo de usuarios.
     *
     * Contiene la lista de permisos que el usuario
     * actualmente autenticado posee.
     *
     * Si el usuario no posee permisos,
     * se marca como 'no autorizado' para el componente
     * independientemente si se requiera un permiso o no.
     */
    getAuthPermissions: {
      immediate: true,
      deep: true,
      handler (value) {
        if (!value) {
          this.authorized = false
          return
        }

        this.checkPermissions()
      }
    },

    /**
     * 'getAuthRoles'. Del módulo de usuarios.
     *
     * Contiene la lista de roles que el usuario
     * actualmente autenticado posee.
     */
    getAuthRoles: {
      immediate: true,
      deep: true,
      handler (value) {
        if (!value) return

        this.checkPermissions()
      }
    },

    /**
     * 'Sobreescribir si'.
     *
     * Esta opción sobreescribe la lógica de validación
     * si está activada, pudiendo escuchar y aplicar la lógica
     * de forma externa, es decir, fuera del componente explícitamente
     * se puede indicar que se muestre o no el componente, independientemente
     * de los permisos que posea el usuario y el componente.
     */
    overrideIf: {
      immediate: true,
      handler () {
        this.checkPermissions()
      }
    }
  },

  methods: {
    checkPermissions () {
      //
      // Si esta opción está activada, sobreescribe la validación
      // y autoriza mostrar el componente.
      //
      // Se utiliza un watch a la variable para escuchar cambios
      // sobre la misma.
      //
      if (this.overrideIf) {
        this.authorized = this.overrideIf
        return
      }

      //
      // La verificación de roles tiene prioridad sobre los permisos.
      //
      // Si hay una lista de roles, entonces se hace una validación sobre
      // éstos pero ya no sobre los permisos.
      //
      if (this.requiresRoles.length > 0) {
        this.checkRoles(this.getAuthRoles)
        return
      }

      //
      // Si no hay una lista de argumentos de permisos requeridos,
      // para este componente se concede el acceso.
      //
      if (this.requiresAuthorizations.length <= 0) {
        this.authorized = true
        return
      }

      //
      // Realiza la validación sobre la lista de permisos del sistema,
      // y los que el usuario tiene agregados actualmente.
      //
      // No está autorizado si el permiso para mostrar el componente
      // no se encuentra dentro de la lista de los permisos del usuario.
      //
      this.authorized = this.getAuthPermissions.some(permission =>
        this.requiresAuthorizations.includes(permission)
      )
    },

    /**
     * Realiza una validación de acceso sobre una lista de roles como argumentos.
     *
     * Si el usuario no posee un rol de los registrados en el sistema,
     * no se le permite su acceso.
     */
    checkRoles (authRoles) {
      if (this.requiresRoles.length <= 0) return

      if (!authRoles || authRoles === undefined) return

      this.authorized = authRoles.some(role => this.requiresRoles.includes(role))
    }
  }
}
</script>

<style lang="scss" scoped></style>

<docs>
Componente para mostrar o no un slot en base a los roles del usuario.

## Casos de uso

Acceso únicamente a los usuarios con roles "admin".

```jsx
<check-authorization :requiresAuthorizations="['admin']">
  Este contenido no se mostrará a no ser que seas "admin".
</check-authorization>
```

Contendo que puede ser accesible a cualquier usuario.

```jsx
<check-authorization :requiresAuthorizations="[]">
  Contenido que es accesible a cualquier usuario.
</check-authorization>
```
</docs>
