<template>
  <b-button
    v-if="!notification.notificated"
    variant="primary"
    size="sm"
    @click="submit(notification.id)"
    :disabled="loading"
    v-bind="$attrs"
  >
    <span v-if="loading">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="ml-2">Espere por favor...</span>
    </span>
    <span v-else>Marcar como leída</span>
  </b-button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotificationsButton',

  props: {
    notification: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      loading: false
    }
  },

  computed: mapGetters('UIModule', { notifications: 'getNotifications' }),

  methods: {
    ...mapActions('UIModule', ['markAsRead']),

    async submit (id) {
      this.loading = true
      await this.markAsRead(id)
      this.loading = false
    }
  }
}
</script>
