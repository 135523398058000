<template>
  <div class="notification_banner bg-danger-soft">
    <b-container>
      <b-row>
        <b-col cols="2" class="my-icon text-danger d-flex justify-content-end align-items-center">
          <i data-feather="alert-triangle"></i>
        </b-col>
        <b-col cols="10" class="d-flex align-items-center">
          <p class="mb-0 text-danger">{{ notification.text }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'NotificationBanner',

  mounted () {
    this.emitSizeToRem(`${this.getSizeToRem()}rem`)
  },

  data () {
    return {
      //
      // FIX: quitar hard code
      notification: {
        title: 'Atención',
        text: 'Estás viendo la aplicación como otro usuario.'
      }
    }
  },

  methods: {
    getSizeToRem () {
      const notificationBanner = document.querySelector('.notification_banner')
      const sizeInRem = notificationBanner.scrollHeight / 16
      return sizeInRem
    },

    emitSizeToRem (size) {
      this.$emit('on-mounted-height', size)
    }
  }
}
</script>

<style lang="scss" scoped>
.notification_banner {
  width: 100%;
  padding: 0.5rem 0;

  position: fixed;
  z-index: 10330;
  top: 0;

  border-top: 1px solid tomato;
  border-bottom: 1px solid tomato;
}
</style>
