<template>
  <div class="tab-pane py-3 fade show " :class="{ active }" role="tabpanel">
    <b-row class="row justify-content-center">
      <b-col :cols="cols" :sm="sm" :md="md" :lg="lg">
        <h3 class="text-primary">{{ title }}</h3>
        <h6 class="card-title">
          {{ description }}
        </h6>

        <slot></slot>

        <b-row class="d-flex justify-content-between pt-5">
          <b-col class="d-flex justify-content-start">
            <slot name="buttonPrev"></slot>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <slot name="buttonNext"></slot>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'XCardWizardBody',

  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },

    description: {
      type: String,
      required: false,
      default: ''
    },

    active: {
      type: Boolean,
      required: false,
      default: false
    },

    cols: {
      type: Number,
      required: true,
      default: 12
    },

    sm: {
      type: Number,
      required: false,
      default: null
    },

    md: {
      type: Number,
      required: false,
      default: null
    },

    lg: {
      type: Number,
      required: false,
      default: null
    }
  }
}
</script>
