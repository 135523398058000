import { es } from '@braid/vue-formulate-i18n'

export default {
  plugins: [es],

  locale: 'es',

  mimes: {
    mp3: 'audio/mpeg',
    png: 'image/png',
    jpg: 'image/jpg'
  },

  // Define our custom slot component(s)
  slotComponents: {
    label: 'XLabel'
  },

  // Define any props we want to pass to our slot component
  slotProps: {
    label: ['required']
  },

  classes: {
    wrapper: (context, classes) => {
      if (context.type !== 'checkbox') {
        return classes.concat(['form-group'])
      }
    },

    label: (context, classes) => {
      return classes.concat(['small', 'text-black', 'font-weight-bolder'])
    },

    input: (context, classes) => {
      if (context.type !== 'checkbox') {
        return classes.concat(['form-control'])
      }
    },

    help: (context, classes) => {
      return classes.concat(['small', 'text-muted'])
    },

    errors: (context, classes) => {
      return classes.concat(['list-unstyled', 'font-weight-bolder', 'text-danger', 'small'])
    }
  }
}
