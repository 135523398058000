<template>
  <li
    id="notificationContainer"
    class="nav-item no-caret d-none d-sm-block mr-3 dropdown-notifications"
    :class="{ show: open }"
    @mouseover="onOpenMenu"
  >
    <a
      class="btn btn-icon btn-transparent-dark dropdown-toggle"
      id="navbarDropdownNotifications"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i data-feather="bell"></i>
    </a>
    <div
      v-show="open"
      @mouseleave="onCloseMenu"
      class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
      aria-labelledby="navbarDropdownNotifications"
      :class="{ show: open }"
    >
      <!-- Título -->
      <h6 class="dropdown-header dropdown-notifications-header">
        <i class="mr-2" data-feather="bell"></i>
        Notificaciones
      </h6>

      <!-- Alert -->
      <a
        v-for="notification in notifications"
        :key="notification.id"
        class="dropdown-item my-cursor dropdown-notifications-item"
      >
        <div class="dropdown-notifications-item-icon bg-white">
          <i data-feather="activity" class="text-success"></i>
        </div>
        <div class="dropdown-notifications-item-content">
          <div class="dropdown-notifications-item-content-details">
            <p class="mb-0 text-sm text-muted">
              {{ $formatTimestamsFromFirebase(notification.created_at) }}
            </p>
          </div>
          <div
            class="dropdown-notifications-item-content-text font-weight-bolder"
            :class="{ 'text-muted font-italic font-weight-300': notification.notificated }"
          >
            {{ notification.content }}
          </div>
          <!-- Button -->
          <NotificationsButton :notification="notification" class="mt-3" block />
        </div>
        <!-- Solo para dar espacio -->
        <div class="dropdown-notifications-item-content ml-3"></div>
      </a>

      <!-- Ver todas las alertas -->
      <router-link
        :to="{ name: 'Notifications' }"
        class="nav-link dropdown-item my-cursor dropdown-notifications-footer"
      >
        Ver todas las alertas
      </router-link>
    </div>
  </li>
</template>

<script>
import feather from 'feather-icons'
import { mapGetters } from 'vuex'
import NotificationsButton from '@/components/Notifications/NotificationsButton'

export default {
  name: 'NotificationsDropDown',

  components: {
    NotificationsButton
  },

  data () {
    return {
      open: false
    }
  },

  computed: mapGetters('UIModule', { notifications: 'getNotifications' }),

  methods: {
    onOpenMenu () {
      this.open = true
      feather.replace()
    },

    onCloseMenu () {
      this.open = false
    }
  }
}
</script>
