<template>
  <base-auth-view>
    <div v-if="maintenanceMode">
      <h1 class="text-center">Modo mantenimiento</h1>
      <p>Estimados inversionistas,</p>

      <p>Queremos informarles que nuestro sistema estará temporalmente fuera de servicio debido a trabajos de mantenimiento esenciales diseñados para mejorar la calidad y seguridad de nuestros servicios. Este mantenimiento es fundamental para continuar ofreciendo el estándar más alto en nuestras operaciones y funcionalidades.</p>

      <p>Nuestro equipo está trabajando diligentemente para restaurar el servicio a la brevedad posible y asegurar que su experiencia sea segura y confiable al reanudar operaciones.</p>

      <p>Ponemos a su disposicion el correo: recepcion.facturas@mbsignatureproperties.com para el envio de sus CFDI.</p>

      <p>Agradecemos enormemente su confianza y apreciamos su colaboración continua.</p>
    </div>
    <FormulateForm v-else @submit="submit" #default="{ isLoading, isValid }">
      <h1 class="text-center">Iniciar sesión</h1>
      <FormulateErrors />

      <!--(email address)-->
      <FormulateInput
        v-model="formValues.email"
        name="email"
        label="Email"
        placeholder="Ingresa la dirección de correo"
        validation="bail|required|email"
        :validation-messages="{
          required: 'El email es requerido',
          email: 'Ingresa un email válido'
        }"
      />

      <!--(password)-->
      <FormulateInput
        v-model="formValues.password"
        name="password"
        label="Contraseña"
        type="password"
        placeholder="Ingresa tu contraseña"
        validation="bail|required"
        :validation-messages="{
          required: 'La contraseña es requerida'
        }"
      />

      <!--(Login button)-->
      <FormulateInput
        type="submit"
        input-class="btn btn-primary btn-block"
        :disabled="isLoading || !isValid"
        :label="isLoading ? 'Iniciando sesión...' : 'Iniciar sesión'"
      />

      <!--(Remember password)-->
      <div class="my-2 text-right">
        <router-link class="small" :to="{ name: 'RecoverPassword' }">
          Recuperar contraseña
        </router-link>
      </div>

      <hr />

      <!--button
        :disabled="isGoogleAuthenticating"
        type="button"
        class="mt-1 btn btn-google btn-block"
        @click="AuthProvider('google')"
      >
        <i class="fab fa-google"></i>
        {{ isGoogleAuthenticating ? 'Espere...' : 'Acceder con Google' }}
      </button-->
    </FormulateForm>
  </base-auth-view>
</template>

<script>
import BaseAuthView from '@/views/Layouts/BaseAuthView'

export default {
  name: 'LoginView',

  components: {
    BaseAuthView
  },

  data () {
    return {
      maintenanceMode: true,

      formValues: {
        email: null,
        password: null
      },

      isGoogleAuthenticating: false
    }
  },

  async created () {
    const getval = await this.$store.dispatch('authModule/loginWithCampus')

    if (getval.error) return

    this.$router.push({ name: 'Dashboard' }).catch(_ => ({}))

    this.$router.go()
  },

  methods: {
    async submit (data) {
      const getval = await this.$store.dispatch('authModule/login', data)
      this.$notify(getval, 'Inicio de sesión', {
        status: getval.error ? 'danger' : 'success'
      })

      if (getval.error) return

      this.$router.push({ name: 'Dashboard' }).catch(_ => ({}))
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-google {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.btn.btn-google .fa-google {
  margin-right: 8px;
}
</style>
