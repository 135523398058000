<template>
  <div id="layoutSidenav_nav" class="no-selection">
    <nav class="sidenav shadow-right sidenav-light">
      <div class="sidenav-menu">
        <div class="nav accordion" id="accordionSidenav">
          <!-- Cuenta -->
          <div class="sidenav-menu-heading">Cuenta</div>

          <a
            class="nav-link bg-danger text-white my-cursor"
            v-if="$store.getters['userModule/isViewingAsAnotherUser']"
            @click="exitOfViewAs"
          >
            <div class="nav-link-icon text-white"><i data-feather="corner-down-left"></i></div>
            Salir de "ver como"
          </a>

          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            <div class="nav-link-icon">
              <i data-feather="home"></i>
            </div>
            Inicio
          </router-link>

          <!-- Notificaciones -->
          <sidebar-link
            :requiresAuthorizations="['read notifications']"
            :to="{ name: 'NotificationsMainView' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="bell"></i>
            </div>
            Notificaciones
            <span
              v-show="getNotificationsCount > 0"
              class="badge badge-warning-soft text-warning ml-auto"
            >
              {{ getNotificationsCount }} Nuevas
            </span>
          </sidebar-link>

          <!-- Notificaciones -->
          <sidebar-link
            :requiresAuthorizations="['indice mensajes']"
            :to="{ name: 'MensajesMainView' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="mail"></i>
            </div>
            Mensajes
            <span
              v-show="getNotificationsCount > 0"
              class="badge badge-warning-soft text-warning ml-auto"
            >
              {{ getNotificationsCount }} Nuevas
            </span>
          </sidebar-link>

          <sidebar-link
            :requiresAuthorizations="['acceso guias']"
            :to="{ name: 'guias-usuario' }"
            :show-permission-help="false"
          >
            <div class="nav-link-icon">
              <i data-feather="book"></i>
            </div>
            Guías de usuario
          </sidebar-link>
          <!-- End Cuenta -->

          <!-- Administración-->
          <sidebar-section-administracion v-if="$hasRoles(['admin'])" :active-accordeon="activeAccordeon"/>
          <!-- End Administración -->

          <!-- Sistema de Reglas -->
          <sidebar-section-reglas v-if="$hasRoles(['admin'])" :active-accordeon="activeAccordeon"/>

          <!-- Catálogos -->
          <sidebar-section-catalogos v-if="$hasRoles(['admin'])" :active-accordeon="activeAccordeon"/>
          <!-- End Catálogos -->

          <!-- Operaciones -->
          <sidebar-section-operaciones v-if="$hasRoles(['admin'])" :active-accordeon="activeAccordeon"/>
          <!-- End Operaciones -->

          <!-- Inversionistas -->
          <sidebar-section-inversionistas v-if="$hasRoles(['inversionista'])"/>
          <!-- End Inversionistas -->

          <!-- Documentación -->
          <sidebar-section-documentacion v-if="$hasRoles(['admin'])"/>

          <!-- Reportes -->
          <!-- TODO: deshabilitamos estas opciones hasta el momento en que se encuentren implementadas -->
          <sidebar-section-reportes v-if="$hasRoles(['admin'])" :active-accordeon="activeAccordeon"/>
          <!-- End Reportes -->
          <!-- Ayuda -->
          <sidebar-section-ayuda v-if="false"/>
          <!-- End Ayuda -->
        </div>
      </div>

      <!-- Sidenav Footer-->
      <div class="sidenav-footer">
        <div class="sidenav-footer-content">
          <div class="sidenav-footer-subtitle">Sesión iniciada como:</div>
          <div class="sidenav-footer-title">
            {{ authUser.email }}
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarSectionAdministracion from './SidebarSectionAdministracion'
import SidebarSectionReglas from './SiderbarSectionReglas'
import SidebarSectionCatalogos from './SidebarSectionCatalogos'
import SidebarSectionOperaciones from './SidebarSectionOperaciones'
import SidebarSectionInversionistas from './SidebarSectionInversionistas'
import SidebarSectionDocumentacion from './SidebarSectionDocumentacion'
import SidebarSectionReportes from './SidebarSectionReportes'
import SidebarSectionAyuda from './SidebarSectionAyuda'
import SidebarLink from './SidebarLink.vue'

export default {
  name: 'Sidebar',

  components: {
    SidebarLink,
    SidebarSectionAdministracion,
    SidebarSectionReglas,
    SidebarSectionCatalogos,
    SidebarSectionOperaciones,
    SidebarSectionInversionistas,
    SidebarSectionDocumentacion,
    SidebarSectionReportes,
    SidebarSectionAyuda
  },

  data () {
    return {
      activeAccordeon: ''
    }
  },

  computed: {
    ...mapGetters('userModule', { authUser: 'getAuthUser' }),

    ...mapGetters('UIModule', ['getNotificationsCount'])
  },

  created () {
    const rutaActual = this.$router.currentRoute
    this.activeAccordeon = rutaActual.meta.accordeon || ''
  },

  methods: {
    async exitOfViewAs () {
      this.$store.commit('userModule/resetViewAsUserMode')
      this.$downAppWhenBannerIsActive('0rem')
      this.$store.dispatch('userModule/getAuthUser')
    }
  }
}
</script>

<style lang="scss" scoped></style>
